.root {
  display: flex;
}

.logo {
  height: 32px;
  margin-top: 12px;
  width: 223px;

  img {
    height: 100%;
  }
}
