@import 'src/variables.scss';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
}

.alteonLink {
  align-items: center;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: $fontFamilyBold;
  font-size: 12px;
  justify-content: center;
  line-height: 15px;
  margin-bottom: 6px;
  margin-top: 16px;
  padding: 0;
  width: 100%;

  &:link, &:visited {
    text-decoration: inherit;
    text-decoration: inherit;
    text-decoration: inherit;
    color: #fff;
  }
}

.alteonLogo {
  margin-right: 10px;
  svg {
    fill: #fff;
  }
}
