@import 'src/variables.scss';

.root {
  background: linear-gradient(67.62deg, rgba(0, 0, 0, 0.65) 44%, rgba(0, 0, 0, 0) 100%), url('/images/train.jpg') 50%;
  background-size: cover;
  height: 100vh;
  padding: 16px 24px;
  position: relative;
}

.widget {
  align-items: center;
  display: flex;
  justify-content: left;
  height: 100%;
  margin-top: -44px;
}

.description {
  color: #fff;
  margin-left: 24px;
  width: 360px;
}

.title {
  font-family:  $fontFamilyBold;
  font-size: 18px;
}

.slogan {
  font-family:  $fontFamilyBold;
  font-size: 46px;
  line-height: 48px;
  margin: 24px 0;
}

.text {
  font-family:  $fontFamilyNormal;
  font-size: 16px;
}

.hint {
  bottom: 36px;
  cursor: pointer;
  position: absolute;
}
