@import 'src/variables.scss';

.root {
  align-items: center;
  bottom: 30px;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 28px;

  &:link, &:visited {
    text-decoration: inherit;
    text-decoration: inherit;
    text-decoration: inherit;
    color: inherit;
  }
}

.avatar {
  background: url('/images/authors/KristinSimmons.jpg');
  background-size: cover;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.info {
  font-family: $fontFamilyMedium;
  font-size: 13px;
  margin: 0 12px;
}

.author {
  align-items: center;
  display: flex;
  font-size: 18px;
  line-height: 24px;
}

.link {
  margin-left: 10px;
  width: 14px;
}
