@import 'src/variables.scss';

.root {
  font-family: $fontFamilyBold;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}

.value {
  align-items: center;
  border: 1px solid #686868;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: space-between;
  padding: 0 16px;
  user-select: none;
}

.name {
  align-items: center;
  display: flex;
}

.menu {
  background: #555555;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  list-style: none;
  margin: 4px 0;
  overflow: hidden;
  padding: 8px;
  position: absolute;
  visibility: hidden;
  width: 100%;

  &.visible {
    visibility: visible;
  }

  >li {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    padding: 8px;
    user-select: none;
  }
}

.active {
  background: $whiteTransparent10;
}

.icon {
  height: 20px;
  margin-right: 12px;
}

.caret {
  height: 20px;

  &.open {
    transform: rotate(180deg);
  }
}
