@import 'src/variables.scss';

.root {
  //display: flex;
  //flex-direction: column;
  //flex-grow: 1;
  //height: auto;
  padding: 18px;
  width: 100%;
}

.body {
  height: calc(100% - 60px);
  position: relative;
  display: flex;
  //flex-grow: 1;
  flex-direction: column;
  //height: 100%;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  //height: calc(100% - 66px);
  margin: 34px 0 24px;

  &::-webkit-scrollbar {
    width: 6px;
    margin-left: 20px;
    padding-left: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: $whiteTransparent10;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 5px;
  }
}

.form {
  background: #393939;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  //max-height: 660px;
  padding: 2px 16px;

  &.withScroll {
    margin-right: 8px;

    &:after {
      width: calc(100% - 14px);
    }

    &:before {
      width: calc(100% - 14px);
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    top: 44px;
    left: 0px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: inset 0px 8px 0px 6px #393939;
  }

  &:after {
    content: '';
    width: 100%;
    height: 26px;
    position: absolute;
    bottom: 12px;
    left: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: inset 0px -8px 0px 6px #393939;
  }
}

.imageContainer {
  display: flex;
  height: 70px;
  margin-bottom: 8px;
}

.imageInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-left: 12px;
}

.imageName, .imageSize, .imageLink {
  font-family: $fontFamilyBold;
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.imageSize {
  font-family: $fontFamilyNormal;
  margin-top: 6px;
}

.imageLink {
  color: $primary;
  cursor: pointer;
  display: flex;
  margin-top: auto;
}

.image {
  max-height: 70px;
}

.formItem {
  margin-top: 16px;
  font-size: 14px;

  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    letter-spacing: 0.15px;
    margin-top: 0;
  }

  :global {
    .alteon-input-wrapper, .alteon-textarea-container {
      label {
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        letter-spacing: 0.15px;
        margin-top: 0;
      }

      input {
        font-size: 16px;
        letter-spacing: 0.15px;
      }
    }

    .alteon-input {
      margin-top: 4px;
    }

    .alteon-tag-input {
      display: flex;
      min-height: 24px;
      padding: 0;
    }

    .alteon-tags-tag {
      margin: 0;
    }

    .alteon-tag-title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    .alteon-tags-close-icon, .alteon-tags-add-icon, .alteon-tags-add-icon-active-input {
      height: 16px;
      width: 16px;
    }

    .alteon-tag-filled {
      padding: 4px 4px 4px 10px;
    }
  }

  textarea.area {
    border: none;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-top: 10px;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;

    &:focus {
      outline: none;
      border: none;
    }
  }
}

.label {
  margin-bottom: 6px;
}

.line {
  height: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.42);
}

.footer {
  align-items: center;
  display: flex;
  justify-content: right;
  margin-top: 8px;
}

.confirm {
  margin-left: 16px;
}

.block {
  align-items: center;
  display: flex;
}

.ethereum {
  align-items: center;
  display: flex;
  background: #151C2F;
  border-radius: 50%;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 6px;
}

.ethereumLogo {
  background: url('/images/ethereum-colored.png');
  background-size: cover;
  border-radius: 50%;
  height: 18px;
  width: 18px;
}
