@import 'src/variables.scss';

.root {
  backdrop-filter: blur(22px);
  background: rgba(51, 51, 51, 0.75);
  border-radius: $componentRadius;
  height: 380px;
  width: 280px;
  z-index: 2;
}

.wide {
  height: auto;
  //height: 74%;
  //max-height: 740px;
  max-height: 90%;
  width: 460px;
  display: flex;
}
